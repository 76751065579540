//import '/assets/css/main.scss'

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Lenis from "@studio-freight/lenis";
import * as THREE from "three";
import imagesLoaded from "imagesloaded";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";

import fragment from "./shaders/fragment.glsl";
import vertex from "./shaders/vertex.glsl";

const lenis = new Lenis({
  duration: 1.2,
  smooth: true,
  infinite: true
});

lenis.on("scroll", ScrollTrigger.update);

gsap.ticker.add((time) => {
  lenis.raf(time * 900);
});

gsap.ticker.lagSmoothing(0);

class Sketch {
  constructor(options) {
    this.time = 0;
    this.container = options.dom;
    this.scene = new THREE.Scene();

    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;

    this.camera = new THREE.PerspectiveCamera(
      70,
      this.width / this.height,
      100,
      2000
    );
    this.camera.position.z = 600;

    this.camera.fov = 2 * Math.atan(this.height / 2 / 600) * (180 / Math.PI);

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    });

    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    this.container.appendChild(this.renderer.domElement);

    this.images = [...document.querySelectorAll("img")];

    // Preload images
    const preloadImages = new Promise((resolve, reject) => {
      imagesLoaded(
        document.querySelectorAll("img"),
        { background: true },
        resolve
      );
    });

    let allDone = [preloadImages];
    this.currentScroll = 0;
    this.shouldUpdatePosition = true;

    Promise.all(allDone).then(() => {
      this.addImages();
      this.forceToPreloadTextures();

      this.resize();
      this.setupResize();
      this.composerPass();
      this.setPosition();
      this.render();
      lenis.on("scroll", (e) => {
        this.currentScroll = lenis.scroll;
        this.scrollSpeed = lenis.actualScroll;
        this.setPosition();
      });
    });
  }

  composerPass() {
    this.composer = new EffectComposer(this.renderer);
    this.renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(this.renderPass);

    //custom shader pass
    this.myEffect = {
      uniforms: {
        tDiffuse: { value: null }
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix 
            * modelViewMatrix 
            * vec4( position, 1.0 );
        }
        `,
      fragmentShader: `
        uniform sampler2D tDiffuse;
        varying vec2 vUv;
        //uniform float scrollSpeed;
        void main(){
          vec2 newUV = vUv;
          float area = smoothstep(0.6,0.,vUv.y);
          newUV.x -= (vUv.x - 0.5)*0.03*area;
          gl_FragColor = texture2D( tDiffuse, newUV);
          //gl_FragColor = vec4(area,0.,0.,1.);
        }
        `
    };

    this.customPass = new ShaderPass(this.myEffect);
    this.customPass.renderToScreen = true;

    this.composer.addPass(this.customPass);
  }

  setupResize() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.width, this.height);

    this.camera.fov =
      2 * Math.atan(this.height / 2 / this.camera.position.z) * (180 / Math.PI);
    this.camera.updateProjectionMatrix();

    this.imageStore.forEach((o) => {
      const bounds = o.img.getBoundingClientRect();
      o.mesh.geometry.dispose();
      o.mesh.geometry = new THREE.PlaneGeometry(
        bounds.width,
        bounds.height,
        1,
        1
      );
      o.mesh.position.y = -bounds.top + this.height / 2 - bounds.height / 2;
      o.mesh.position.x = bounds.left - this.width / 2 + bounds.width / 2;
    });
  }

  addImages() {
    this.material = new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        uImage: { value: 0 }
      },
      fragmentShader: fragment,
      vertexShader: vertex
    });

    this.materials = [];

    this.imageStore = this.images.map((img) => {
      let bounds = img.getBoundingClientRect();
      let geometry = new THREE.PlaneGeometry(bounds.width, bounds.height, 1, 1);
      let texture = new THREE.Texture(img);
      texture.needsUpdate = true;
      texture.generateMipmaps = false;
      let material = this.material.clone();

      this.materials.push(material);

      material.uniforms.uImage.value = texture;

      let mesh = new THREE.Mesh(geometry, material);
      this.scene.add(mesh);
      return {
        img: img,
        mesh: mesh,
        top: bounds.top,
        left: bounds.left,
        width: bounds.width,
        height: bounds.height
      };
    });
  }

  forceToPreloadTextures() {
    const imageStoreBufferGroup = new THREE.Group();
    this.scene.add(imageStoreBufferGroup);

    this.imageStoreBuffer = this.imageStore.map((o) => {
      const meshClone = o.mesh.clone();
      imageStoreBufferGroup.add(meshClone);
      meshClone.material.visibility = 0;
      return { ...o, mesh: meshClone };
    });

    this.imageStoreBuffer.forEach((o) => {
      let bounds = o.img.getBoundingClientRect();
      o.mesh.position.y = 0;
      o.mesh.position.x = bounds.left - this.width / 2 + bounds.width / 2;
    });

    this.renderer.render(this.scene, this.camera);
    imageStoreBufferGroup.visible = false;

    return imageStoreBufferGroup;
  }

  setPosition() {
    this.imageStore.forEach((o) => {
      let bounds = o.img.getBoundingClientRect();
      o.mesh.position.y =
        -bounds.top + window.innerHeight / 2 - bounds.height / 2;
      o.mesh.position.x = bounds.left - this.width / 2 + bounds.width / 2;
    });
  }

  render() {
    this.time += 0.008;
    this.setPosition();
    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;
    this.composer.render();
    window.requestAnimationFrame(this.render.bind(this));
  }
}

if (window.innerWidth > 1024) {
  new Sketch({
    dom: document.getElementById("container")
  });
}

const tl = gsap.timeline({
  paused: true,
  onStart: () => (document.body.style.overflow = "hidden"),
  onReverseComplete: () => (document.body.style.overflow = "")
});

tl.fromTo(
  ".img-modal",
  { translateY: "110%" },
  {
    duration: 1.2,
    translateY: "0%",
    ease: "expo.inOut"
  }
);
tl.to(
  ".img-modal .img-container",
  1.4,
  {
    clipPath: "polygon(0 0%, 100% 0%, 100% 100%, 0% 100%)",
    ease: "expo.inOut"
  },
  "-=0.9"
);
tl.to(
  ".item",
  {
    duration: 0.5,
    top: 0,
    autoAlpha: 1,
    ease: "power4.inOut",
    stagger: 0.7
  },
  "-=0.5"
);

const openButtons = document.querySelectorAll(".open");

openButtons.forEach((openButton) => {
  openButton.addEventListener("click", () => {
    if (tl.reversed()) {
      tl.play();
    } else {
      tl.restart();
    }
  });
});

const closeButton = document.querySelector(".close");
if (closeButton) {
  closeButton.addEventListener("click", () => {
    tl.reverse();
  });
}

document.addEventListener("DOMContentLoaded", () => {
  const $imagesContainer = document.getElementById("images-container");
  const $lightbox = document.getElementById("lightbox");
  const gallery = document.querySelectorAll(".image-gallery");
  const previewBox = document.querySelector(".img-modal");
  const currentImg = previewBox.querySelector(".current-img");
  const totalImg = previewBox.querySelector(".total-img");
  const prevBtn = document.querySelector(".prev-container");
  const nextBtn = document.querySelector(".next-container");

  totalImg.textContent = gallery.length;

  $imagesContainer.addEventListener("click", (e) => {
    const imageWrapper = e.target.closest(".image-gallery");
    if (imageWrapper) {
      const clickedImageIndex = Array.from(gallery).indexOf(imageWrapper);
      updatePreview(clickedImageIndex);
    }
  });

  prevBtn.onclick = () => {
    let currentIndex = parseInt(currentImg.textContent, 10) - 1;
    currentIndex = (currentIndex - 1 + gallery.length) % gallery.length;
    updatePreview(currentIndex);
  };

  nextBtn.onclick = () => {
    let currentIndex = parseInt(currentImg.textContent, 10) - 1;
    currentIndex = (currentIndex + 1) % gallery.length;
    updatePreview(currentIndex);
  };

  let lastIndex = 0;

  function updatePreview(index) {
    const image = gallery[index].querySelector("img").cloneNode();
    image.classList.add("img-fade");
    $lightbox.innerHTML = "";
    $lightbox.appendChild(image);
    currentImg.textContent = index + 1;

    gsap.fromTo(
      image,
      { opacity: 0 },
      {
        duration: 1,
        opacity: 1,
        ease: "power2.inOut",
        onComplete: () => {
          image.classList.remove("img-fade");
        }
      }
    );
  }

  if (window.innerWidth > 1024) {
    const galleryContainer = document.querySelector(".gallery-container");

    document.addEventListener("mousemove", (e) => {
      const { clientX, clientY } = e;
      const screenWidth = window.innerWidth;
      const prevButton = document.querySelector(".prev");
      const nextButton = document.querySelector(".next");

      const rect = galleryContainer.getBoundingClientRect();
      if (
        clientX >= rect.left &&
        clientX <= rect.right &&
        clientY >= rect.top &&
        clientY <= rect.bottom
      ) {
        prevButton.style.top = `${clientY}px`;
        nextButton.style.top = `${clientY}px`;

        if (clientX < screenWidth / 2) {
          prevButton.style.left = `${clientX - 0}px`;
        } else {
          nextButton.style.left = `${clientX + 0}px`;
        }
      }
    });
  }
});

function initPreloader() {
  document.body.classList.add("is-hidden");

  let loadedCount = 0;
  const imagesToLoad = document.querySelectorAll(".bcg").length;
  let loadingProgress = 0;

  const progressTl = gsap.timeline({
    paused: true,
    onUpdate: progressUpdate,
    onComplete: loadComplete
  });

  progressTl.to(".progress span", {
    width: "100%",
    duration: 1,
    ease: "none"
  });

  function progressUpdate() {
    loadingProgress = Math.round(progressTl.progress() * 100);
    document.querySelector(".txt-perc").textContent = `${loadingProgress}%`;
  }

  function loadComplete() {
    document.body.classList.remove("is-hidden");
    const preloaderOutTl = gsap.timeline();

    preloaderOutTl
      .to(".progress", {
        y: 100,
        autoAlpha: 0,
        ease: "back.in",
        duration: 0.3
      })
      .to(
        ".txt-perc-wrapper",
        {
          y: 100,
          autoAlpha: 0,
          ease: "back.in",
          duration: 0.7
        },
        "-=0.1"
      )
      .set("body", {
        className: "-=is-loading"
      })
      .set("#intro", {
        className: "+=is-loaded"
      })
      .to("#intro", {
        opacity: 1,
        visibility: "visible"
      })
      .to(
        "#preloader",
        {
          yPercent: 200,
          ease: "power4.inOut",
          duration: 1.5
        },
        "-=0.8"
      )
      .set("#preloader", {
        className: "+=is-hidden"
      })
      .add(() => {
        animateWords(
          ".word-section1 .word",
          "#gooey-effect-section1 feGaussianBlur"
        );
        animateWords(
          ".word-section2 .word",
          "#gooey-effect-section2 feGaussianBlur"
        );
      }, "-=1.1")

      .to(".gallery", {
        autoAlpha: 1,
        visibility: "visible",
        ease: "power3.out",
        duration: 5,
        y: "0px"
      });

    return preloaderOutTl;
  }

  const loadProgress = () => {
    loadedCount++;
    loadingProgress = loadedCount / imagesToLoad;
    gsap.to(progressTl, {
      progress: loadingProgress,
      duration: 5,
      ease: "none"
    });
  };

  imagesLoaded(".bcg", { background: true }, () => {
    loadProgress();
  });

  const introTl = gsap.timeline();
  introTl.to("#intro", {
    autoAlpha: 1,
    ease: "power1.none",
    duration: 0.7
  });
}

function animateWords(selector, blurEffectId) {
  const words = document.querySelectorAll(selector);
  const totalWords = words.length;
  let currentWord = 0;

  if (words.length === 0) {
    console.log("Check the selector:", selector);
    return;
  }

  gsap.set(words, { opacity: 0, visibility: "visible" });

  const animateWord = () => {
    const word = words[currentWord];

    const blurAnimation = (stdDeviation) => {
      gsap.to(blurEffectId, {
        attr: { stdDeviation: stdDeviation },
        duration: 1.5
      });
    };

    if (window.innerWidth < 1024) {
      gsap.to(word, {
        opacity: 1,
        duration: 4.1,
        ease: "power4.inOut",
        onStart: () => blurAnimation(2),
        onComplete: () => {
          gsap.to(word, {
            opacity: 0,
            duration: 4.1,
            ease: "power4.inOut",
            delay: 0.000009,
            onStart: () => blurAnimation(2)
          });
        }
      });
    } else {
      gsap.to(word, {
        opacity: 1,
        duration: 4.1,
        ease: "power4.inOut",
        onStart: () => blurAnimation(5),
        onComplete: () => {
          gsap.to(word, {
            opacity: 0,
            duration: 4.1,
            ease: "power4.inOut",
            delay: 0.000009,
            onStart: () => blurAnimation(5)
          });
        }
      });
    }

    currentWord = (currentWord + 1) % totalWords;

    gsap.to(words[currentWord], {
      opacity: 0,
      delay: 3.5,
      onComplete: animateWord
    });
  };
  animateWord();
}

function initCursor() {
  document.querySelectorAll("[data-cursor]").forEach((item) => {
    item.addEventListener("mouseover", (e) => {
      if (item.dataset.cursor === "pointer") {
        cursor.style.backgroundColor = "rgba(88, 74, 61, 1) !important";
        cursor.style.mixBlendMode = "hard-light";
        cursor.style.setProperty("--circle-size", "20px");
      }
      if (item.dataset.cursor === "pointer2") {
        cursor.style.backgroundColor = "rgba(88, 74, 61, 1) !important";
        cursor.style.mixBlendMode = "hard-light";
        cursor.style.setProperty("--circle-size", "40px");
      }
      if (item.dataset.cursor === "pointer3") {
        cursor.style.backgroundColor = "rgba(88, 74, 61, 0) !important";
        cursor.style.mixBlendMode = "unsent";
        cursor.style.boxShadow = "none";
        cursor.style.border = "none";
        cursor.style.setProperty("--circle-size", "100px");
      }
    });
    item.addEventListener("mouseout", (e) => {
      cursor.style.backgroundColor = "rgba(88, 74, 61, 1) !important";
      cursor.style.mixBlendMode = "hard-light";
      cursor.style.setProperty("--circle-size", "6px");
    });
  });
  const cursor = document.querySelector("#cursor");

  const mouse = { x: 0, y: 0 };
  const previousMouse = { x: 0, y: 0 };
  const circle = { x: 0, y: 0 };

  let currentScale = 0;
  let currentAngle = 0;

  window.addEventListener("mousemove", (e) => {
    mouse.x = e.x;
    mouse.y = e.y;
  });

  const speed = 0.3;

  // Start animation
  const tick = () => {
    // MOVE
    circle.x += (mouse.x - circle.x) * speed;
    circle.y += (mouse.y - circle.y) * speed;
    const translateTransform = `translate(${circle.x}px, ${circle.y}px)`;

    // SQUEEZE
    const deltaMouseX = mouse.x - previousMouse.x;
    const deltaMouseY = mouse.y - previousMouse.y;
    previousMouse.x = mouse.x;
    previousMouse.y = mouse.y;
    const mouseVelocity = Math.min(
      Math.sqrt(deltaMouseX ** 2 + deltaMouseY ** 2) * 4,
      150
    );
    const scaleValue = (mouseVelocity / 150) * 1;
    currentScale += (scaleValue - currentScale) * speed;
    const scaleTransform = `scale(${1 + currentScale}, ${1 - currentScale})`;

    // ROTATE
    const angle = (Math.atan2(deltaMouseY, deltaMouseX) * 180) / Math.PI;
    if (mouseVelocity > 20) {
      currentAngle = angle;
    }

    cursor.style.transform = `${translateTransform} ${scaleTransform}`;
  };

  function loop() {
    tick();
    requestAnimationFrame(loop);
  }

  requestAnimationFrame(loop);

  const cursorModifiers = document.querySelectorAll("[cursor-class]");

  cursorModifiers.forEach((curosrModifier) => {
    curosrModifier.addEventListener("mouseenter", function () {
      const className = this.getAttribute("cursor-class");
      cursor.classList.add(className);
      cursor.style.backgroundColor = "rgba(0, 0, 0, 0) !important";
      cursor.style.mixBlendMode = "unsent";
      cursor.style.boxShadow = "none";
      cursor.style.border = "none";
    });

    curosrModifier.addEventListener("mouseleave", function () {
      const className = this.getAttribute("cursor-class");
      cursor.classList.remove(className);
      cursor.style.backgroundColor = "rgba(0, 0, 0, 0) !important";
      cursor.style.mixBlendMode = "unsent";
      cursor.style.boxShadow = "none";
      cursor.style.border = "none";
    });
  });
}

gsap.set(".block-to-show", { translateY: "100%" });

function toggleBlockVisibility(
  blockToShow,
  isVisible,
  onCompleteCallback = () => {}
) {
  const tl = gsap.timeline({
    defaults: { duration: 1.5, ease: "power4.inOut" },
    onStart: () => {
      if (isVisible) {
        document.body.style.overflow = "hidden";
      }
    },
    onComplete: () => {
      document.body.style.overflow = "";
      if (isVisible) {
        lenis.stop();
      } else {
        lenis.start();
      }
      onCompleteCallback();
    },
    onReverseComplete: () => {
      document.body.style.overflow = "";
      lenis.start();
    }
  });
  tl.to(".audio__button", { opacity: 0, duration: 0.5 }, "-=1");
  tl.to(
    blockToShow,
    {
      translateY: isVisible ? 0 : "100%",
      clipPath: isVisible
        ? "polygon(0 0%, 100% 0%, 100% 100%, 0% 100%)"
        : "polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)"
    },
    "-=0.6"
  );
  tl.to(".active-btn-fixed", { opacity: 1, duration: 0.5 }, "-=0.1");

  if (!isVisible) {
    tl.to(blockToShow, { autoAlpha: 0 }, ">");
  }

  return tl;
}

function playAudio(audio, button, duration = 1) {
  let volume = 0;
  const maxVolume = 1;
  const step = maxVolume / ((duration * 1000) / 50);
  audio.volume = 0;
  audio.play();
  button.textContent = "Pause";
  button.classList.add("active-btn-fixed");
  gsap.to(button, { opacity: 0, duration: 0.5 });

  const fadeAudioInterval = setInterval(() => {
    if (volume < maxVolume - step) {
      volume += step;
      audio.volume = volume;
    } else {
      audio.volume = maxVolume;
      clearInterval(fadeAudioInterval);
      gsap.to(button, { opacity: 1, duration: 0.5 });
    }
  }, 50);
}

function pauseAudio(audio, button, duration = 1) {
  let volume = audio.volume;
  const step = volume / ((duration * 1000) / 50);
  gsap.to(button, { opacity: 0, duration: 0.5 });

  const fadeAudioInterval = setInterval(() => {
    if (volume > step) {
      volume -= step;
      audio.volume = volume;
    } else {
      clearInterval(fadeAudioInterval);
      audio.pause();
      audio.volume = 1;
      button.textContent = "Listen to our story";
      button.classList.remove("active-btn-fixed");
      gsap.to(".audio__button", { opacity: 1, duration: 0.5 });
    }
  }, 50);
}

// Event listeners
document.querySelectorAll(".play-pause-button").forEach((button, index) => {
  const blockToShow = document.querySelectorAll(".block-to-show")[index];
  const audioId = button.getAttribute("data-audio-id");
  const audio = document.querySelector(`audio[data-audio-id="${audioId}"]`);

  if (!audio) return;

  let isPlaying = false;
  let animationTl;

  button.addEventListener("click", () => {
    isPlaying = !isPlaying;

    if (isPlaying) {
      animationTl = toggleBlockVisibility(blockToShow, true, () =>
        playAudio(audio, button)
      );
    } else {
      pauseAudio(audio, button);
      animationTl.reverse();
    }
  });

  audio.addEventListener("ended", () => {
    isPlaying = false;
    pauseAudio(audio, button);
    animationTl.reverse();
  });
});

document.querySelectorAll("audio").forEach((audio, index) => {
  const audioId = audio.getAttribute("data-audio-id");
  const lyricsContainer = document.querySelector(
    `.audio__text[data-audio-id="${audioId}"]`
  );

  function updateLyricsHighlight() {
    requestAnimationFrame(updateLyricsHighlight);

    const currentTime = audio.currentTime;
    const spans = lyricsContainer.querySelectorAll("span");

    spans.forEach((span) => {
      const startTime = parseFloat(span.dataset.startTime);

      if (currentTime >= startTime) {
        span.classList.add("active");
      } else {
        span.classList.remove("active");
      }
    });
  }

  audio.addEventListener("playing", updateLyricsHighlight);
  audio.addEventListener("pause", () => {
    const spans = lyricsContainer.querySelectorAll("span");
    spans.forEach((span) => span.classList.remove("active"));
  });
});

const lyricsData = [
  {
    audioId: "1",
    lyrics: [
      {
        text: "Hey there,",
        type: "word",
        startTime: 5.87
      },
      {
        text: "welcome to Ozero!\n",
        type: "word",
        startTime: 7.18
      },
      {
        text: "My name is Kanstantsin",
        type: "word",
        startTime: 10.03
      },
      {
        text: "and I am a part of our small family studio",
        type: "word",
        startTime: 11.84
      },
      {
        text: "driven",
        type: "word",
        startTime: 14.87
      },
      {
        text: "by our love for architecture,",
        type: "word",
        startTime: 15.5
      },
      {
        text: "design",
        type: "word",
        startTime: 17.67
      },
      {
        text: "and visual art.",
        type: "word",
        startTime: 18.07
      },
      {
        text: "Here at Ozero",
        type: "word",
        startTime: 21.78
      },
      {
        text: "we don’t just create images,",
        type: "word",
        startTime: 23.42
      },
      {
        text: "we like to think of ourselves",
        type: "word",
        startTime: 25.41
      },
      {
        text: "as storytellers.",
        type: "word",
        startTime: 26.9
      },
      {
        text: "Each project",
        type: "word",
        startTime: 28.93
      },
      {
        text: "is a universe",
        type: "word",
        startTime: 29.6
      },
      {
        text: "with its own",
        type: "word",
        startTime: 30.95
      },
      {
        text: "character",
        type: "word",
        startTime: 31.5
      },
      {
        text: "and story.",
        type: "word",
        startTime: 32.47
      },
      {
        text: "Our passion",
        type: "word",
        startTime: 34.63
      },
      {
        text: "is in crafting",
        type: "word",
        startTime: 36.01
      },
      {
        text: "distinctive narratives",
        type: "word",
        startTime: 37.09
      },
      {
        text: "through our visuals,",
        type: "word",
        startTime: 38.62
      },
      {
        text: "empowering your project.\n",
        type: "word",
        startTime: 40.75
      },
      {
        text: "Thanks for stepping by.",
        type: "word",
        startTime: 43.95
      },
      {
        text: "We would be happy",
        type: "word",
        startTime: 45.86
      },
      {
        text: "to tell your story.",
        type: "word",
        startTime: 47.34
      }
    ]
  },
  {
    audioId: "2",
    lyrics: [
      {
        text: "Hey there,",
        type: "word",
        startTime: 5.87
      },
      {
        text: "welcome to Ozero!\n",
        type: "word",
        startTime: 7.18
      },
      {
        text: "My name is Kanstantsin",
        type: "word",
        startTime: 10.03
      },
      {
        text: "and I am a part of our small family studio",
        type: "word",
        startTime: 11.84
      },
      {
        text: "driven",
        type: "word",
        startTime: 14.87
      },
      {
        text: "by our love for architecture,",
        type: "word",
        startTime: 15.5
      },
      {
        text: "design",
        type: "word",
        startTime: 17.67
      },
      {
        text: "and visual art.",
        type: "word",
        startTime: 18.07
      },
      {
        text: "Here at Ozero",
        type: "word",
        startTime: 21.78
      },
      {
        text: "we don’t just create images,",
        type: "word",
        startTime: 23.42
      },
      {
        text: "we like to think of ourselves",
        type: "word",
        startTime: 25.41
      },
      {
        text: "as storytellers.",
        type: "word",
        startTime: 26.9
      },
      {
        text: "Each project",
        type: "word",
        startTime: 28.93
      },
      {
        text: "is a universe",
        type: "word",
        startTime: 29.6
      },
      {
        text: "with its own",
        type: "word",
        startTime: 30.95
      },
      {
        text: "character",
        type: "word",
        startTime: 31.5
      },
      {
        text: "and story.",
        type: "word",
        startTime: 32.47
      },
      {
        text: "Our passion",
        type: "word",
        startTime: 34.63
      },
      {
        text: "is in crafting",
        type: "word",
        startTime: 36.01
      },
      {
        text: "distinctive narratives",
        type: "word",
        startTime: 37.09
      },
      {
        text: "through our visuals,",
        type: "word",
        startTime: 38.62
      },
      {
        text: "empowering your project.\n",
        type: "word",
        startTime: 40.75
      },
      {
        text: "Thanks for stepping by.",
        type: "word",
        startTime: 43.95
      },
      {
        text: "We would be happy",
        type: "word",
        startTime: 45.86
      },
      {
        text: "to tell your story.",
        type: "word",
        startTime: 47.34
      }
    ]
  }
];

function generateLyrics() {
  lyricsData.forEach((audioLyrics) => {
    const audioId = audioLyrics.audioId;
    const lyricsContainer = document.querySelector(
      `.audio__text[data-audio-id="${audioId}"]`
    );

    if (!lyricsContainer) {
      console.warn(`Lyrics container not found for audioId: ${audioId}`);
      return;
    }

    let currentDiv = document.createElement("div");
    currentDiv.classList.add("audio__text-inner");
    lyricsContainer.appendChild(currentDiv);

    audioLyrics.lyrics.forEach((item) => {
      if (item.text.includes("\n")) {
        const parts = item.text.split("\n");
        parts.forEach((part, partIndex) => {
          if (partIndex > 0) {
            currentDiv = document.createElement("div");
            currentDiv.classList.add("audio__text-inner");
            lyricsContainer.appendChild(currentDiv);
          }
          if (part) {
            const span = document.createElement("span");
            span.textContent = part;
            span.dataset.startTime = item.startTime;
            currentDiv.appendChild(span);
          }
        });
      } else {
        const span = document.createElement("span");
        span.textContent = item.text;
        span.dataset.startTime = item.startTime;
        currentDiv.appendChild(span);
      }
    });
  });
}

function initContent() {
  initCursor();
  animateWords();
  initPreloader();
  generateLyrics();
}
window.addEventListener("load", function () {
  initContent();
});
